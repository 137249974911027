<template>
  <div style="display: inline-block">
    <b-button
      :size="size"
      variant="primary"
      class="mr-2"
      @click="onShowModal()"
      :disabled="disabled"
      >{{ allDisabled ? 'View' : 'Edit' }}
    </b-button>
    <b-modal
      v-model="showModal"
      centered
      no-close-on-backdrop
      scrollable
      title="Edit"
      id="edit-invoice-information"
      ref="modal"
      size="xl"
    >
      <b-overlay :show="modalloading">
        <b-tabs v-model="tabIndex" content-class="mt-3" lazy>
          <b-tab title="Edit">
            <b-row class="mb-5">
              <b-col md="12" class="my-1">
                <h5 class="subtitle">Invoice</h5>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  class="invoice_status required"
                  label-for="invoice_status"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Status"
                >
                  <b-form-radio-group
                    id="invoice_status"
                    v-model="invoice_status"
                    :options="options.invoice_status"
                    :disabled="allDisabled"
                    text-field="label"
                    name="invoice_status"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1"></b-col>
              <b-col md="12" class="mt-1 mb-4">
                <b-row v-for="(item, index) in file_info" :key="index">
                  <b-col md="3" class="my-1">
                    <b-form-group
                      :class="{
                        required: invoice_status == 2
                      }"
                      class="invoice_no"
                      label-for="invoice_no"
                      label-cols-sm="5"
                      label-cols-lg="5"
                      label-align-sm="right"
                      label-size="sm"
                      :label="`invoice No.${index + 1}`"
                    >
                      <b-form-input
                        v-model="item.invoice_no"
                        :disabled="allDisabled"
                        :placeholder="$t('page.please_choose_input')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="my-1">
                    <b-form-group
                      :class="{
                        required: invoice_status == 2
                      }"
                      class="invoice_date"
                      label-for="invoice_date"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      label="Invoice date"
                    >
                      <b-input-group>
                        <template v-slot:append>
                          <div
                            v-if="item.invoice_date"
                            class="datepicker-x"
                            @click="initialTime(item, 'invoice_date')"
                          >
                            <b-icon-x font-scale="1.45"></b-icon-x>
                          </div>
                          <b-input-group-text>
                            <b-icon-calendar2-date></b-icon-calendar2-date>
                          </b-input-group-text>
                        </template>
                        <b-form-datepicker
                          v-model="item.invoice_date"
                          :disabled="allDisabled"
                          :date-format-options="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                          }"
                          :hide-header="true"
                          locale="de"
                        ></b-form-datepicker>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="my-1">
                    <b-form-group
                      class="invoice_no"
                      label-for="invoice_no"
                      label-cols-sm="5"
                      label-cols-lg="5"
                      label-align-sm="right"
                      label-size="sm"
                      :label="'Quantity'"
                    >
                      <b-form-input
                        type="number"
                        min="0"
                        v-model="item.product_qty"
                        :disabled="allDisabled"
                        :placeholder="$t('page.please_choose_input')"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3" class="my-1">
                    <b-form-group
                      :class="{
                        required: invoice_status == 2
                      }"
                      class="refundable invoice_status"
                      label-for="refundable"
                      label-cols-sm="4"
                      label-cols-lg="3"
                      label-align-sm="right"
                      label-size="sm"
                      label="VAT Refund"
                    >
                      <sv-select
                        v-model="item.refundable"
                        :placeholder="$t('page.please_choose')"
                        :options="options.refundable"
                        :reduce="(options) => options.value"
                        :disabled="allDisabled"
                        label="label"
                      >
                        <!--  -->
                      </sv-select>
                      <div
                        class="exhibit-input2 ml-2"
                        @click="uploadcheck(item.purchase_invoice_file_id)"
                      >
                        View
                      </div>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" class="my-1">
                <h5 class="subtitle">Product Information</h5>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  class="currency"
                  label-for="currency"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Currency"
                >
                  <sv-select
                    v-model="currency"
                    :placeholder="$t('page.please_choose')"
                    :options="options.currency"
                    :reduce="(options) => options.value"
                    :clearable="false"
                    :disabled="allDisabled"
                    label="label"
                  >
                    <!--  -->
                  </sv-select>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1"></b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  :class="{
                    required: invoice_status == 2
                  }"
                  class="net_amount"
                  label-for="net_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Unit Net Amount"
                >
                  <b-form-input
                    v-model="net_amount"
                    :disabled="allDisabled"
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  :class="{
                    required: invoice_status == 2
                  }"
                  class="vat_amount"
                  label-for="vat_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Unit VAT Amount"
                >
                  <b-form-input
                    v-model="vat_amount"
                    :disabled="allDisabled"
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  :class="{
                    required: invoice_status == 2
                  }"
                  class="gross_amount"
                  label-for="gross_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Unit Gross Amount"
                >
                  <b-form-input
                    v-model="gross_amount"
                    :disabled="allDisabled"
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  :class="{
                    required: invoice_status == 2
                  }"
                  class="freight_amount"
                  label-for="freight_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Freight"
                >
                  <b-form-input
                    v-model="freight_amount"
                    :disabled="allDisabled"
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  :class="{
                    required: invoice_status == 2
                  }"
                  class="provisional_estimate_adjustment"
                  label-for="provisional_estimate_adjustment"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Provisional Estimate Adjustment"
                >
                  <b-form-input
                    v-model="provisional_estimate_adjustment"
                    :disabled="allDisabled"
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="12" class="my-1">
                <h5 class="subtitle"></h5>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  class="total_net_amount"
                  label-for="total_net_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Total Net Amount"
                >
                  <b-form-input
                    v-model="total_net_amount"
                    disabled
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  class="total_vat_amount"
                  label-for="total_vat_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Total VAT Amount"
                >
                  <b-form-input
                    v-model="total_vat_amount"
                    disabled
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6" class="my-1">
                <b-form-group
                  class="total_gross_amount"
                  label-for="total_gross_amount"
                  label-cols-sm="4"
                  label-cols-lg="3"
                  label-align-sm="right"
                  label-size="sm"
                  label="Total Gross Amount"
                >
                  <b-form-input
                    v-model="total_gross_amount"
                    disabled
                    :placeholder="this.$t('page.please_choose_input')"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab title="Logs">
            <SimpleTable
              :items="items"
              :fields="fields"
              :tableId="'product_info_detail'"
              style="margin-top: 20px; min-height: 200px"
            ></SimpleTable
          ></b-tab>
        </b-tabs>
      </b-overlay>
      <template v-slot:modal-footer="{ close }">
        <b-button
          v-if="tabIndex == 0"
          variant="primary"
          @click="submit()"
          :disabled="submitDisabled"
        >
          Submit
        </b-button>
        <b-button variant="secondary" @click="close()"> Cancel </b-button>
      </template></b-modal
    >
  </div>
</template>

<script>
import apiBus from '@/common/apiBus/index';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import JwtService from '@/common/services/jwt';
import SimpleTable from '@/components/Table/Simple.vue';
// import SelectLoading from '@/components/Loading/Index';
export default {
  name: 'EditInvoiceInformation',

  data() {
    return {
      submitDisabled: false,
      modalloading: false,
      showModal: false,
      invoice_status: null,
      currency: 0,
      // invoice_no: null,
      // invoice_date: null,
      file_info: [],
      net_amount: null,
      vat_amount: null,
      gross_amount: null,
      freight_amount: null,
      provisional_estimate_adjustment: null,
      refundable: null,

      total_gross_amount: null,
      total_net_amount: null,
      total_vat_amount: null,

      tabIndex: 0,

      items: [],
      fields: [
        {
          key: 'created_at',
          label: 'Time'
        },
        {
          key: 'system_user',
          label: 'System User'
        },
        {
          key: 'log_action',
          label: 'Action'
        },
        {
          key: 'detail',
          label: 'Details'
        }
      ],

      options: {
        invoice_status: [
          { label: 'Processing', value: 1 },
          { label: 'Complete', value: 2 }
        ],
        currency: [
          { label: 'EUR', value: 'EUR' },
          { label: 'CNY', value: 'CNY' },
          { label: 'HUF', value: 'HUF' }
        ],
        refundable: [
          { label: 'Yes', value: 1 },
          { label: 'No', value: 2 },
          { label: 'Uncertain', value: 3 }
        ]
      }
    };
  },
  props: [
    'id',
    'purchase_invoice_id',
    'initFn',
    'size',
    'disabled',
    'allDisabled',
    'qty'
  ],

  components: { SimpleTable },
  methods: {
    initialTime(obj, name) {
      console.log(obj, name);
      obj[name] = null;
    },
    onShowModal() {
      this.showModal = true;
      this.modalloading = true;
      this.submitDisabled = false;
      apiBus.purchaseInvoice
        .getPurchaseInvoiceDetail({
          purchase_overview_id: this.id
        })
        .then((data) => {
          this.modalloading = false;
          var item = data.data.data;
          this.invoice_status = item.invoice_status;
          this.currency = item.currency;
          this.file_info = item.file_info;
          this.net_amount = item.net_amount;
          this.vat_amount = item.vat_amount;
          this.gross_amount = item.gross_amount;
          this.freight_amount = item.freight_amount;
          this.provisional_estimate_adjustment =
            item.provisional_estimate_adjustment;
          this.refundable = item.refundable;

          this.total_gross_amount = isNaN(
            Number(this.gross_amount.replace('.', '').replace(',', '.'))
          )
            ? null
            : (
                Number(this.gross_amount.replace('.', '').replace(',', '.')) *
                this.qty
              )
                .toFixed(4)
                .toString()
                .replace('.', ',');

          this.total_net_amount = isNaN(
            Number(this.net_amount.replace('.', '').replace(',', '.'))
          )
            ? null
            : (
                Number(this.net_amount.replace('.', '').replace(',', '.')) *
                this.qty
              )
                .toFixed(4)
                .toString()
                .replace('.', ',');

          this.total_vat_amount = isNaN(
            Number(this.vat_amount.replace('.', '').replace(',', '.'))
          )
            ? null
            : (
                Number(this.vat_amount.replace('.', '').replace(',', '.')) *
                this.qty
              )
                .toFixed(4)
                .toString()
                .replace('.', ',');
        })
        .catch((error) => {
          console.log(error);
          this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });

      apiBus.purchaseInvoice
        .getPurchaseInvoiceLog({
          purchase_invoice_id: this.purchase_invoice_id
        })
        .then((data) => {
          // this.modalloading = false;
          this.items = data.data.data;
          console.log(this.items);
        })
        .catch((error) => {
          console.log(error);
          // this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });
    },
    submit() {
      let invoice_no_arr = [];
      this.file_info.forEach((item) => {
        console.log(item.invoice_no);
        if (item.invoice_no != '') {
          invoice_no_arr.push(item.invoice_no);
        }
      });
      let len = invoice_no_arr.length;
      let setLen = [...new Set(invoice_no_arr)].length;
      if (len != setLen) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 9999999999, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'The invoice number is repeated, please fill in again !' // 提示内容
        });
        return false;
      }
      let sum =
        Number(this.net_amount.replace('.', '').replace(',', '.')) +
        Number(this.vat_amount.replace('.', '').replace(',', '.'));
      if (
        this.invoice_status == 2 &&
        (this.net_amount == null ||
          this.net_amount == '' ||
          this.vat_amount == null ||
          this.vat_amount == '' ||
          this.gross_amount == null ||
          this.gross_amount == '' ||
          this.freight_amount == null ||
          this.freight_amount == '' ||
          this.provisional_estimate_adjustment == '' ||
          this.provisional_estimate_adjustment == null)
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Fields marked with * are required!' // 提示内容
        });
        return false;
      }

      if (this.invoice_status == 2) {
        try {
          this.file_info.forEach((key) => {
            if (
              key.invoice_no == null ||
              key.invoice_no == '' ||
              key.invoice_date == null ||
              key.invoice_date == '' ||
              key.refundable == null ||
              key.refundable == ''
            ) {
              throw Error();
            }
          });
        } catch (e) {
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Fields marked with * are required!' // 提示内容
          });
          return false;
        }
      }

      if (
        (this.net_amount &&
          isNaN(this.net_amount.replace('.', '').replace(',', '.'))) ||
        (this.vat_amount &&
          isNaN(this.vat_amount.replace('.', '').replace(',', '.'))) ||
        (this.gross_amount &&
          isNaN(this.gross_amount.replace('.', '').replace(',', '.'))) ||
        (this.freight_amount &&
          isNaN(this.freight_amount.replace('.', '').replace(',', '.'))) ||
        (this.provisional_estimate_adjustment &&
          isNaN(
            this.provisional_estimate_adjustment
              .replace('.', '')
              .replace(',', '.')
          ))
      ) {
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Please fill in the correct Purchase Price !' // 提示内容
        });
        return false;
      }

      if (
        sum + 0.03 < Number(this.gross_amount.replace(',', '.')) ||
        sum - 0.03 > Number(this.gross_amount.replace(',', '.'))
      ) {
        // Gross Amount
        this.$store.dispatch(PROMPT_STATUS, {
          dismissCountDown: 5, //初始提示作用于定时器
          alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
          alertText: 'Amount is invalid , please re-enter.' // 提示内容
        });
        return false;
      }

      this.submitDisabled = true;
      this.modalloading = true;
      apiBus.purchaseInvoice
        .savePurchaseInvoiceDetail({
          purchase_overview_id: this.id,
          status: this.invoice_status,
          currency: this.currency,
          file_info: this.file_info,
          net_amount: this.net_amount,
          vat_amount: this.vat_amount,
          gross_amount: this.gross_amount,
          freight_amount: this.freight_amount,
          provisional_estimate_adjustment: this.provisional_estimate_adjustment,
          refundable: this.refundable
        })
        .then((data) => {
          console.log(data);
          this.submitDisabled = false;
          this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Download Successful.' // 提示内容
          });
          this.initFn();
          this.showModal = false;
        })
        .catch((error) => {
          console.log(error);
          this.submitDisabled = false;
          this.modalloading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: error.data.message // 提示内容
          });
        });
    },
    uploadcheck(id) {
      var childWin = window.open(
        `/rest/api/purchase/print-purchase-invoice?purchase_invoice_file_id=${id}&token=${JwtService.getToken()}`,
        'newwindow',
        'height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
      );
      childWin.onload = function () {
        childWin.print();
      };
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    init() {}
  },

  computed: {},
  mounted() {
    this.init();
  },
  watch: {
    net_amount(o) {
      this.total_net_amount = isNaN(
        Number(o.replace('.', '').replace(',', '.'))
      )
        ? null
        : (Number(o.replace('.', '').replace(',', '.')) * this.qty)
            .toFixed(4)
            .toString()
            .replace('.', ',');
    },
    vat_amount(o) {
      this.total_vat_amount = isNaN(
        Number(o.replace('.', '').replace(',', '.'))
      )
        ? null
        : (Number(o.replace('.', '').replace(',', '.')) * this.qty)
            .toFixed(4)
            .toString()
            .replace('.', ',');
    },
    gross_amount(o) {
      this.total_gross_amount = isNaN(
        Number(o.replace('.', '').replace(',', '.'))
      )
        ? null
        : (Number(o.replace('.', '').replace(',', '.')) * this.qty)
            .toFixed(4)
            .toString()
            .replace('.', ',');
    }
  }
};
</script>
<style scoped>
.confirm-delivery {
  color: #fff;
}
.confirm-delivery-modal {
  height: 300px;
}
</style>
